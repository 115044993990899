"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.packageHealthCheckRequestAPI = exports.PACKAGE_HEALTH_CHECK_REQUEST_ACTION = void 0;
const _1 = require(".");
exports.PACKAGE_HEALTH_CHECK_REQUEST_ACTION = 'PACKAGE_HEALTH_CHECK_REQUEST';
const packageHealthCheckRequestAPI = (package_name, asset_id) => (0, _1.api)('package', {
    action: exports.PACKAGE_HEALTH_CHECK_REQUEST_ACTION,
    fillable: { asset: { id: asset_id }, package: { package_name } },
});
exports.packageHealthCheckRequestAPI = packageHealthCheckRequestAPI;
