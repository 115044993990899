"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assetHireRequestInstallerAPI = exports.ASSET_HIRE_REQUEST_INSTALLER_ACTION = exports.assetHireOffHireConfirmAPI = exports.ASSET_HIRE_OFF_HIRE_CONFIRM_ACTION = exports.assetHireOffHireConfirmCheckAPI = exports.ASSET_HIRE_OFF_HIRE_CONFIRM_CHECK_ACTION = exports.assetHireReturnAssetAPI = exports.ASSET_HIRE_RETURN_ASSET_ACTION = exports.assetHireUpdateNotesAPI = exports.ASSET_HIRE_UPDATE_NOTES_ACTION = exports.assetHireCopyAPI = exports.ASSET_HIRE_COPY_ACTION = exports.assetHireReHireAPI = exports.ASSET_HIRE_RE_HIRE_ACTION = exports.assetHireOffHireCancelAPI = exports.ASSET_HIRE_OFF_HIRE_CANCEL_ACTION = exports.assetHireOffHireAPI = exports.ASSET_HIRE_OFF_HIRE_ACTION = exports.assetHireConfirmChangeAPI = exports.ASSET_HIRE_CONFIRM_CHANGE_ACTION = exports.assetHireRejectMonitoringAPI = exports.ASSET_HIRE_REJECT_MONITORING_ACTION = exports.assetHireApproveMonitoringAPI = exports.ASSET_HIRE_APPROVE_MONITORING_ACTION = exports.assetHireConfirmHireAPI = exports.ASSET_HIRE_CONFIRM_HIRE_ACTION = exports.assetHireInstallAPI = exports.ASSET_HIRE_INSTALL_ACTION = exports.assetHireDeleteAPI = exports.ASSET_HIRE_DELETE_ACTION = exports.assetHireUpdateAPI = exports.ASSET_HIRE_UPDATE_ACTION = exports.assetHireInsertAPI = exports.ASSET_HIRE_INSERT_ACTION = exports.assetHireConfigAPI = exports.assetHireInfoAPI = void 0;
const _Api_1 = require("@Api");
const assetHireInfoAPI = (id) => (0, _Api_1.api)('asset_hire', {
    action: 'ASSET_HIRE_INFO',
    fillable: {
        asset_hire: {
            id,
        },
    },
});
exports.assetHireInfoAPI = assetHireInfoAPI;
const assetHireConfigAPI = () => (0, _Api_1.api)('asset_hire', {
    action: 'ASSET_HIRE_CONFIG',
});
exports.assetHireConfigAPI = assetHireConfigAPI;
exports.ASSET_HIRE_INSERT_ACTION = 'ASSET_HIRE_INSERT';
const assetHireInsertAPI = (asset_hire) => (0, _Api_1.api)('asset_hire', {
    action: exports.ASSET_HIRE_INSERT_ACTION,
    fillable: {
        asset_hire,
    },
});
exports.assetHireInsertAPI = assetHireInsertAPI;
exports.ASSET_HIRE_UPDATE_ACTION = 'ASSET_HIRE_UPDATE';
const assetHireUpdateAPI = (asset_hire) => (0, _Api_1.api)('asset_hire', {
    action: exports.ASSET_HIRE_UPDATE_ACTION,
    fillable: {
        asset_hire,
    },
});
exports.assetHireUpdateAPI = assetHireUpdateAPI;
exports.ASSET_HIRE_DELETE_ACTION = 'ASSET_HIRE_DELETE';
const assetHireDeleteAPI = (id) => (0, _Api_1.api)('asset_hire', {
    action: exports.ASSET_HIRE_DELETE_ACTION,
    fillable: {
        asset_hire: {
            id,
        },
    },
});
exports.assetHireDeleteAPI = assetHireDeleteAPI;
exports.ASSET_HIRE_INSTALL_ACTION = 'ASSET_HIRE_INSTALL';
const assetHireInstallAPI = (assetHireInstall) => (0, _Api_1.api)('asset_hire', {
    action: exports.ASSET_HIRE_INSTALL_ACTION,
    fillable: {
        asset_hire: assetHireInstall,
    },
});
exports.assetHireInstallAPI = assetHireInstallAPI;
exports.ASSET_HIRE_CONFIRM_HIRE_ACTION = 'ASSET_HIRE_CONFIRM_HIRE';
const assetHireConfirmHireAPI = (id, location) => (0, _Api_1.api)('asset_hire', {
    action: exports.ASSET_HIRE_CONFIRM_HIRE_ACTION,
    fillable: {
        asset_hire: {
            id,
            location,
        },
    },
});
exports.assetHireConfirmHireAPI = assetHireConfirmHireAPI;
exports.ASSET_HIRE_APPROVE_MONITORING_ACTION = 'ASSET_HIRE_APPROVE_MONITORING';
const assetHireApproveMonitoringAPI = (id, monitoring_dates) => (0, _Api_1.api)('asset_hire', {
    action: exports.ASSET_HIRE_APPROVE_MONITORING_ACTION,
    fillable: {
        asset_hire: {
            id,
            monitoring_dates,
        },
    },
});
exports.assetHireApproveMonitoringAPI = assetHireApproveMonitoringAPI;
exports.ASSET_HIRE_REJECT_MONITORING_ACTION = 'ASSET_HIRE_REJECT_MONITORING';
const assetHireRejectMonitoringAPI = (id, reason) => (0, _Api_1.api)('asset_hire', {
    action: exports.ASSET_HIRE_REJECT_MONITORING_ACTION,
    fillable: {
        asset_hire: {
            id,
            reason,
        },
    },
});
exports.assetHireRejectMonitoringAPI = assetHireRejectMonitoringAPI;
exports.ASSET_HIRE_CONFIRM_CHANGE_ACTION = 'ASSET_HIRE_CONFIRM_CHANGE';
const assetHireConfirmChangeAPI = (id) => (0, _Api_1.api)('asset_hire', {
    action: exports.ASSET_HIRE_CONFIRM_CHANGE_ACTION,
    fillable: {
        asset_hire: {
            id,
        },
    },
});
exports.assetHireConfirmChangeAPI = assetHireConfirmChangeAPI;
exports.ASSET_HIRE_OFF_HIRE_ACTION = 'ASSET_HIRE_OFF_HIRE';
const assetHireOffHireAPI = (id, off_hire_date, returned, time_lapse_compilation_required) => (0, _Api_1.api)('asset_hire', {
    action: exports.ASSET_HIRE_OFF_HIRE_ACTION,
    fillable: {
        asset_hire: Object.assign({ id,
            off_hire_date,
            returned }, (time_lapse_compilation_required !== null ? { time_lapse_compilation_required } : {})),
    },
});
exports.assetHireOffHireAPI = assetHireOffHireAPI;
exports.ASSET_HIRE_OFF_HIRE_CANCEL_ACTION = 'ASSET_HIRE_OFF_HIRE_CANCEL';
const assetHireOffHireCancelAPI = (id) => (0, _Api_1.api)('asset_hire', {
    action: exports.ASSET_HIRE_OFF_HIRE_CANCEL_ACTION,
    fillable: {
        asset_hire: {
            id,
        },
    },
});
exports.assetHireOffHireCancelAPI = assetHireOffHireCancelAPI;
exports.ASSET_HIRE_RE_HIRE_ACTION = 'ASSET_HIRE_RE_HIRE';
const assetHireReHireAPI = (id) => (0, _Api_1.api)('asset_hire', {
    action: exports.ASSET_HIRE_RE_HIRE_ACTION,
    fillable: {
        asset_hire: {
            id,
        },
    },
});
exports.assetHireReHireAPI = assetHireReHireAPI;
exports.ASSET_HIRE_COPY_ACTION = 'ASSET_HIRE_COPY';
const assetHireCopyAPI = (id, copy) => (0, _Api_1.api)('asset_hire', {
    action: exports.ASSET_HIRE_COPY_ACTION,
    fillable: {
        asset_hire: {
            id,
            copy,
        },
    },
});
exports.assetHireCopyAPI = assetHireCopyAPI;
exports.ASSET_HIRE_UPDATE_NOTES_ACTION = 'ASSET_HIRE_UPDATE_NOTES';
const assetHireUpdateNotesAPI = (id, notes) => (0, _Api_1.api)('asset_hire', {
    action: exports.ASSET_HIRE_UPDATE_NOTES_ACTION,
    fillable: {
        asset_hire: {
            id,
            notes,
        },
    },
});
exports.assetHireUpdateNotesAPI = assetHireUpdateNotesAPI;
exports.ASSET_HIRE_RETURN_ASSET_ACTION = 'ASSET_HIRE_RETURN_ASSET';
const assetHireReturnAssetAPI = (id, asset_returned_date) => (0, _Api_1.api)('asset_hire', {
    action: exports.ASSET_HIRE_RETURN_ASSET_ACTION,
    fillable: {
        asset_hire: {
            id,
            asset_returned_date,
        },
    },
});
exports.assetHireReturnAssetAPI = assetHireReturnAssetAPI;
exports.ASSET_HIRE_OFF_HIRE_CONFIRM_CHECK_ACTION = 'ASSET_HIRE_OFF_HIRE_CONFIRM_CHECK';
const assetHireOffHireConfirmCheckAPI = (id) => (0, _Api_1.api)('asset_hire', {
    action: exports.ASSET_HIRE_OFF_HIRE_CONFIRM_CHECK_ACTION,
    fillable: {
        asset_hire: {
            id,
        },
    },
});
exports.assetHireOffHireConfirmCheckAPI = assetHireOffHireConfirmCheckAPI;
exports.ASSET_HIRE_OFF_HIRE_CONFIRM_ACTION = 'ASSET_HIRE_OFF_HIRE_CONFIRM';
const assetHireOffHireConfirmAPI = (id) => (0, _Api_1.api)('asset_hire', {
    action: exports.ASSET_HIRE_OFF_HIRE_CONFIRM_ACTION,
    fillable: {
        asset_hire: {
            id,
        },
    },
});
exports.assetHireOffHireConfirmAPI = assetHireOffHireConfirmAPI;
exports.ASSET_HIRE_REQUEST_INSTALLER_ACTION = 'ASSET_HIRE_REQUEST_INSTALLER';
const assetHireRequestInstallerAPI = (id) => (0, _Api_1.api)('asset_hire', {
    action: exports.ASSET_HIRE_REQUEST_INSTALLER_ACTION,
    fillable: {
        asset_hire: {
            id,
        },
    },
});
exports.assetHireRequestInstallerAPI = assetHireRequestInstallerAPI;
