"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assetRequestsRelocationCheckAPI = exports.ASSET_REQUESTS_RELOCATION_CHECK_ACTION = exports.assetRequestsRelocationAPI = exports.ASSET_REQUESTS_RELOCATION_ACTION = exports.assetRequestsFootageAPI = exports.ASSET_REQUESTS_FOOTAGE_ACTION = exports.assetRequestsHealthCheckCancelAPI = exports.ASSET_REQUESTS_HEALTH_CHECK_CANCEL_ACTION = exports.assetRequestsHealthCheckAPI = exports.ASSET_REQUESTS_HEALTH_CHECK_ACTION = void 0;
const _Api_1 = require("@Api");
exports.ASSET_REQUESTS_HEALTH_CHECK_ACTION = 'ASSET_REQUESTS_HEALTH_CHECK';
const assetRequestsHealthCheckAPI = (id) => (0, _Api_1.api)('asset_requests', {
    action: exports.ASSET_REQUESTS_HEALTH_CHECK_ACTION,
    fillable: {
        asset: { id },
    },
});
exports.assetRequestsHealthCheckAPI = assetRequestsHealthCheckAPI;
exports.ASSET_REQUESTS_HEALTH_CHECK_CANCEL_ACTION = 'ASSET_REQUESTS_HEALTH_CHECK_CANCEL';
const assetRequestsHealthCheckCancelAPI = (id) => (0, _Api_1.api)('asset_requests', {
    action: exports.ASSET_REQUESTS_HEALTH_CHECK_CANCEL_ACTION,
    fillable: {
        asset: { id },
    },
});
exports.assetRequestsHealthCheckCancelAPI = assetRequestsHealthCheckCancelAPI;
exports.ASSET_REQUESTS_FOOTAGE_ACTION = 'ASSET_REQUESTS_FOOTAGE';
const assetRequestsFootageAPI = (id, request) => (0, _Api_1.api)('asset_requests', {
    action: exports.ASSET_REQUESTS_FOOTAGE_ACTION,
    fillable: {
        asset: { id },
        request,
    },
});
exports.assetRequestsFootageAPI = assetRequestsFootageAPI;
exports.ASSET_REQUESTS_RELOCATION_ACTION = 'ASSET_REQUESTS_RELOCATION';
const assetRequestsRelocationAPI = (asset_id, latitude, longitude, site_id, location) => (0, _Api_1.api)('asset_requests', {
    action: exports.ASSET_REQUESTS_RELOCATION_ACTION,
    fillable: {
        asset_hire: Object.assign({ asset_id,
            latitude,
            longitude }, (site_id ? { site_id, location } : {})),
    },
});
exports.assetRequestsRelocationAPI = assetRequestsRelocationAPI;
exports.ASSET_REQUESTS_RELOCATION_CHECK_ACTION = 'ASSET_REQUESTS_RELOCATION_CHECK';
const assetRequestsRelocationCheckAPI = (id) => (0, _Api_1.api)('asset_requests', {
    action: exports.ASSET_REQUESTS_RELOCATION_CHECK_ACTION,
    fillable: {
        asset: { id },
    },
});
exports.assetRequestsRelocationCheckAPI = assetRequestsRelocationCheckAPI;
