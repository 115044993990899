"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.supportDeskInfoAPI = exports.SUPPORT_DESK_INFO_ACTION = void 0;
const _Api_1 = require("@Api");
exports.SUPPORT_DESK_INFO_ACTION = 'SUPPORT_DESK_INFO';
const supportDeskInfoAPI = (sdId) => (0, _Api_1.api)('support_desk', {
    action: exports.SUPPORT_DESK_INFO_ACTION,
    fillable: { support_desk: { id: sdId } },
});
exports.supportDeskInfoAPI = supportDeskInfoAPI;
