"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.linksInfoAPI = exports.LINKS_INFO_ACTION = void 0;
const _Api_1 = require("@Api");
exports.LINKS_INFO_ACTION = 'LINKS_INFO';
const linksInfoAPI = (entityType, entityId) => (0, _Api_1.api)('links', {
    action: exports.LINKS_INFO_ACTION,
    fillable: {
        links: {
            entity_type: entityType,
            entity_id: entityId,
        },
    },
});
exports.linksInfoAPI = linksInfoAPI;
