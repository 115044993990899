"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.supportTasksMaintenanceRequestCompletedAPI = exports.SUPPORT_TASKS_MAINTENANCE_REQUEST_COMPLETED_ACTION = exports.supportTasksCancelMaintenanceRequestAPI = exports.SUPPORT_TASKS_CANCEL_MAINTENANCE_REQUEST_ACTION = exports.supportTasksRelocationRequestCompletedAPI = exports.SUPPORT_TASKS_RELOCATION_REQUEST_COMPLETED_ACTION = exports.supportTasksCancelFootageRequestAPI = exports.SUPPORT_TASKS_CANCEL_FOOTAGE_REQUEST_ACTION = exports.supportTasksFootageRequestCompletedAPI = exports.SUPPORT_TASKS_FOOTAGE_REQUEST_COMPLETED_ACTION = exports.supporTasksHealthCheckCompletedAPI = exports.SUPPORT_TASKS_HEALTH_CHECK_COMPLETED_ACTION = exports.supporTasksEditAPI = exports.SUPPORT_TASKS_EDIT_ACTION = exports.supporTasksTimelapseCompileAPI = exports.SUPPORT_TASKS_TIME_LAPSE_COMPILED_ACTION = exports.supportTasksListExportAPI = exports.supportTasksListFiltersAPI = exports.supportTasksListAPI = exports.SUPPORT_TASKS_LIST_ACTION = void 0;
const _Api_1 = require("@Api");
const _Constants_1 = require("@Constants");
const searchFiltersHelpers_1 = require("@Helpers/searchFiltersHelpers");
const ListHeaderConst_1 = require("@Constants/ListHeaderConst");
exports.SUPPORT_TASKS_LIST_ACTION = 'SUPPORT_TASKS_LIST';
const supportTasksListAPI = (basic_search, advance_search, page, sort_header = undefined, sort_header_direction = ListHeaderConst_1.SortDirection.ASC, items_per_page = _Constants_1.ITEM_PER_PAGE) => (0, _Api_1.api)('support_tasks', {
    action: exports.SUPPORT_TASKS_LIST_ACTION,
    items_per_page,
    page,
    sort_by: sort_header ? { [sort_header.value]: sort_header_direction } : {},
    search: {
        basic_search,
        advance_search: (0, searchFiltersHelpers_1.filtersFEtoBE)(advance_search),
    },
});
exports.supportTasksListAPI = supportTasksListAPI;
const supportTasksListFiltersAPI = () => (0, _Api_1.api)('support_tasks', {
    action: 'SUPPORT_TASKS_SEARCH_FILTERS',
    fillable: {},
}).then((response) => (0, searchFiltersHelpers_1.filtersBEtoFE)(response.filters));
exports.supportTasksListFiltersAPI = supportTasksListFiltersAPI;
const supportTasksListExportAPI = (basic_search, advance_search, sort_header = undefined, sort_header_direction = ListHeaderConst_1.SortDirection.ASC) => (0, _Api_1.api)('support_tasks', {
    action: 'SUPPORT_TASKS_LIST_EXPORT',
    sort_by: sort_header ? { [sort_header.value]: sort_header_direction } : {},
    search: {
        basic_search,
        advance_search: (0, searchFiltersHelpers_1.filtersFEtoBE)(advance_search),
    },
});
exports.supportTasksListExportAPI = supportTasksListExportAPI;
exports.SUPPORT_TASKS_TIME_LAPSE_COMPILED_ACTION = 'SUPPORT_TASKS_TIME_LAPSE_COMPILED';
const supporTasksTimelapseCompileAPI = (task_id) => (0, _Api_1.api)('support_tasks', {
    action: exports.SUPPORT_TASKS_TIME_LAPSE_COMPILED_ACTION,
    fillable: {
        task_id,
    },
});
exports.supporTasksTimelapseCompileAPI = supporTasksTimelapseCompileAPI;
exports.SUPPORT_TASKS_EDIT_ACTION = 'SUPPORT_TASKS_EDIT';
const supporTasksEditAPI = (id, notes) => (0, _Api_1.api)('support_tasks', {
    action: exports.SUPPORT_TASKS_EDIT_ACTION,
    fillable: {
        support_task: {
            id,
            notes,
        },
    },
});
exports.supporTasksEditAPI = supporTasksEditAPI;
exports.SUPPORT_TASKS_HEALTH_CHECK_COMPLETED_ACTION = 'SUPPORT_TASKS_HEALTH_CHECK_COMPLETED';
const supporTasksHealthCheckCompletedAPI = (task_id) => (0, _Api_1.api)('support_tasks', {
    action: exports.SUPPORT_TASKS_HEALTH_CHECK_COMPLETED_ACTION,
    fillable: {
        task_id,
    },
});
exports.supporTasksHealthCheckCompletedAPI = supporTasksHealthCheckCompletedAPI;
exports.SUPPORT_TASKS_FOOTAGE_REQUEST_COMPLETED_ACTION = 'SUPPORT_TASKS_FOOTAGE_REQUEST_COMPLETED';
const supportTasksFootageRequestCompletedAPI = (task_id) => (0, _Api_1.api)('support_tasks', {
    action: exports.SUPPORT_TASKS_FOOTAGE_REQUEST_COMPLETED_ACTION,
    fillable: {
        task_id,
    },
});
exports.supportTasksFootageRequestCompletedAPI = supportTasksFootageRequestCompletedAPI;
exports.SUPPORT_TASKS_CANCEL_FOOTAGE_REQUEST_ACTION = 'SUPPORT_TASKS_CANCEL_FOOTAGE_REQUEST';
const supportTasksCancelFootageRequestAPI = (task_id) => (0, _Api_1.api)('support_tasks', {
    action: exports.SUPPORT_TASKS_CANCEL_FOOTAGE_REQUEST_ACTION,
    fillable: {
        task_id,
    },
});
exports.supportTasksCancelFootageRequestAPI = supportTasksCancelFootageRequestAPI;
exports.SUPPORT_TASKS_RELOCATION_REQUEST_COMPLETED_ACTION = 'SUPPORT_TASKS_RELOCATION_REQUEST_COMPLETED';
const supportTasksRelocationRequestCompletedAPI = (task_id) => (0, _Api_1.api)('support_tasks', {
    action: exports.SUPPORT_TASKS_RELOCATION_REQUEST_COMPLETED_ACTION,
    fillable: {
        task_id,
    },
});
exports.supportTasksRelocationRequestCompletedAPI = supportTasksRelocationRequestCompletedAPI;
exports.SUPPORT_TASKS_CANCEL_MAINTENANCE_REQUEST_ACTION = 'SUPPORT_TASKS_CANCEL_MAINTENANCE_REQUEST';
const supportTasksCancelMaintenanceRequestAPI = (task_id) => (0, _Api_1.api)('support_tasks', {
    action: exports.SUPPORT_TASKS_CANCEL_MAINTENANCE_REQUEST_ACTION,
    fillable: {
        task_id,
    },
});
exports.supportTasksCancelMaintenanceRequestAPI = supportTasksCancelMaintenanceRequestAPI;
exports.SUPPORT_TASKS_MAINTENANCE_REQUEST_COMPLETED_ACTION = 'SUPPORT_TASKS_MAINTENANCE_REQUEST_COMPLETED';
const supportTasksMaintenanceRequestCompletedAPI = (task_id) => (0, _Api_1.api)('support_tasks', {
    action: exports.SUPPORT_TASKS_MAINTENANCE_REQUEST_COMPLETED_ACTION,
    fillable: {
        task_id,
    },
});
exports.supportTasksMaintenanceRequestCompletedAPI = supportTasksMaintenanceRequestCompletedAPI;
