"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.externalInfoHistoryAPI = exports.EXTERNAL_INFO_HISTORY_ACTION = void 0;
const _Api_1 = require("@Api");
exports.EXTERNAL_INFO_HISTORY_ACTION = 'EXTERNAL_INFO_HISTORY';
const externalInfoHistoryAPI = (entityType, entityId, sectionId, page) => (0, _Api_1.api)('external_info', {
    action: exports.EXTERNAL_INFO_HISTORY_ACTION,
    fillable: {
        external_info: {
            entity_type: entityType,
            entity_id: entityId,
            section_id: sectionId,
        }
    },
    page,
    items_per_page: 50,
});
exports.externalInfoHistoryAPI = externalInfoHistoryAPI;
